import style from "./MainPage.module.scss"
import {Card} from "../../components/Card/Card";
import {useFetchAllToursQuery} from "../../store/toursSlice";
import feedback from "../../assets/feedback.jpg"
import about_us from "../../assets/about_us.jpg"
import archive from "../../assets/archive.JPG"
import {useEffect, useState} from "react";
export function MainPage() {
    const {data, isLoading, error} = useFetchAllToursQuery();
    const [tours, setTours] = useState([]);

    useEffect(() => {
        if (data) {
            const archiveTours = data.filter(el => el.is_archive === false);
            setTours(archiveTours);
        }
    }, [data]);

    return (
        <div className={style.content}>
            {isLoading && <div><img className={style.loading} src={require("../../assets/loadind.gif")} alt="loading" /></div>}
            {error && <div className={style.error}>Ошибка при загрузке туров</div>}
            <Card cover={about_us} link="/about_us" title="О нас" />
            {(!isLoading && !error && tours && tours.length === 0) && <div>Нет туров</div>}
            {tours && tours.map(el => <Card {...el} link={`/tour/${el.id}`} />)}
            <Card cover={feedback} link="/feedback_book" title="Ваши впечатления" />
            <Card cover={archive} link="/archive" title="Наши кейсы" />

            <a target="_blank" href="https://t.me/SecretSauce_businesstrip" className={style.join}>Присоединяйтесь к нашему каналу в Telegram</a>
            <a target="_blank"
               href="https://api.whatsapp.com/send/?phone=79852899913&text&type=phone_number&app_absent=0"
               className={style.join}>Написать в WhatsApp</a>
        </div>
    );
}
